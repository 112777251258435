import React from 'react';
import Barcode from 'react-barcode';
import { code_separators } from 'utilities';

function PaymentBarcode({ code, notify, text, copy }) {
    return (
        <div className="mt-7 px-4 pb-6">
            <div className="w-full lg:w-1/2 mx-auto">
                <p className="text-payment_code_title text-gray-hero_subtitle font-face-poppinsregular">{text}</p>
            </div>
            <div className="justify-between flex flex-row items-center lg:w-1/2 lg:mx-auto">
                <p className="text-xl font-face-poppinsbold">{code_separators(code)}</p>
                <button id='copy-button' onClick={notify}>
                    <p className="text-blue-600 font-face-poppinsbold">{copy}</p>
                </button>
            </div>
            <div className="w-full lg:w-1/2 lg:mx-auto h-auto rounded-xl py-2 mt-4 px-3 lg:px-0 shadow-xl flex justify-center">
                <Barcode value={code} displayValue={false} width={2} />
            </div>
        </div>
    );
}

export default PaymentBarcode;
