import React from 'react';
import { PaymentPartnerLogo } from './PaymentPartnerLogo';
// payment category component
const PaymentPartnerCollapse = (props) => {
    const { data, isBalance, type, logo } = props;
    return (
        <div className="flex w-full bg-white pl-2 pr-4 py-2 rounded-b-2xl flex-wrap min-h-10">
            {isBalance ? (
                <div className="flex items-center justify-center p-1.5 align-center">
                    <img src={logo} alt="Logo" className="max-h-6" />
                    <p className="text-md ml-2">{type}</p>
                </div>
            ) : (
                data?.map((items, index) => {
                    return (
                        <div key={index} className="flex items-center justify-center align-center m-2">
                            <PaymentPartnerLogo items={items} />
                        </div>
                    );
                })
            )}
        </div>
    );
};

export default PaymentPartnerCollapse;
