import React from 'react';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import { MerchantInfo, PaymentBarcode, MerchantPaymentInfo } from 'presentations/shared';

function MerchantBarcodeInfo({ total, channel, notify, code, fee }) {
    const { t } = useTranslation();
    return (
        <div id='payment-code' className="w-full h-auto mt-7">
            <div className="p-4 bg-white mt-2 h-auto w-full border-b-2">
                <MerchantInfo channel={channel} text={t('payment_via')} />
                <PaymentBarcode code={code} notify={notify} text={t('payment_code')} copy={t('copy')} />
                <MerchantPaymentInfo
                    total={total}
                    channel={channel}
                    text_total={t('total_payment')}
                    text_fee={t('admin_fee')}
                    fee={fee}
                    text_admin={t('fee')}
                />
            </div>
        </div>
    );
}

export default MerchantBarcodeInfo;
