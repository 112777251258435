import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BuyPackageButton from '../../shared/BuyPackageButton';
import '../../assets/css/OtpInput.css';
import { BNI_DD, BRI_DD } from 'utilities';
// import ModalAlert from '../../shared/ModalAlert';
import { bindAccount, unbindAccount } from 'domain/use-case';

function OtpPage({
    onClick,
    text_button,
    isBalance,
    handleOtp,
    otpWarning,
    paymentId,
    type = 'binding',
    validateOrder = () => {},
}) {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [otpOcde, setOtpCode] = useState('');
    const { t } = useTranslation();
    const stateGlobal = useSelector((state) => state);
    const [minutes, setMinutes] = useState();
    const [seconds, setSeconds] = useState();
    const isAllEmpty = otp.some((element) => element === '');

    useEffect(() => {
        if (BNI_DD === stateGlobal.upp_data.payment_info.payment_provider) {
            setMinutes(5);
            setSeconds(0);
        } else {
            setMinutes(1);
            setSeconds(0);
        }
        // eslint-disable-next-line
    }, []);

    const countHourMinutesSeconds = useCallback(
        (interval) => {
            if (minutes === 0) {
                clearInterval(interval);
            } else {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        },
        [minutes]
    );
    const countDown = useCallback(
        (interval) => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else if (seconds === 0) {
                countHourMinutesSeconds(interval);
            }
        },
        [countHourMinutesSeconds, seconds]
    );

    useEffect(() => {
        let myInterval = setInterval(() => {
            countDown(myInterval);
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [countDown, minutes, seconds]);

    const handleResendOTP = () => {
        // resendOTP();
        setMinutes(0);
        setSeconds(10);
    };

    const changeHandler = (index, value) => {
        const sanitizedValue = value.replace(/\D/g, '').slice(0, 1);
        const newOTPValues = [...otp];
        newOTPValues[index] = sanitizedValue;
        setOtp(newOTPValues);
        setOtpCode(newOTPValues.join().replace(/,/g, ''));
        if (sanitizedValue && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };
    const onBind = async (partner, authCode) => {
        let binding_body = {
            payment_provider: stateGlobal.upp_data.payment_info.payment_provider,
        };
        if (partner === BRI_DD || partner === BNI_DD) {
            binding_body = {
                payment_provider: partner,
                auth_code: authCode,
            };
        }
        if (partner === BRI_DD || partner === BNI_DD) {
            try {
                const response = await bindAccount(binding_body, stateGlobal.upp_data.token);

                if (response && response.status_code === '00') {
                    handleOtp(true, '');
                } else if (response && response.data?.status_desc?.includes('Invalid Token')) {
                    handleOtp(true, response.data.status_desc);
                } else if (response && response.data?.payment_response?.response_desc?.includes('OTP Lifetime Expired')) {
                    handleOtp(false, t('three_times_wording'));
                } else if (response && response.data?.payment_response?.response_desc?.includes('Invalid OTP')) {
                    handleOtp(false, 'Kode yang kamu masukkan salah');
                } else if (response && response.data?.status_desc?.includes('Timeout to Worker')) {
                    handleOtp(true, response.data.status_desc);
                } else if (response && response.data?.status_desc?.includes('Limit Binding Attempt')) {
                    handleOtp(false, response.data.status_desc);
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    const onUnbind = async (partner, authCode) => {
        let binding_body = {
            ...stateGlobal.upp_data.payment_info,
            payment_provider: stateGlobal.upp_data.payment_info.payment_provider,
            payment_id: paymentId,
        };
        if (partner === BNI_DD) {
            binding_body = {
                ...binding_body,
                payment_provider: partner,
                auth_code: authCode,
            };
        }
        if (partner === BNI_DD) {
            try {
                const response = await unbindAccount(binding_body, stateGlobal.upp_data.token);
                if (response && response.status_code === '00') {
                    handleOtp(true, '');
                    validateOrder(stateGlobal.upp_data.token);
                } else if (response.data.payment_response.response_desc === 'Too Many Requests') {
                    handleOtp(false, t('three_times_wording'));
                } else if (response.data.payment_response.response_desc === 'Invalid OTP') {
                    handleOtp(false, 'Kode yang kamu masukkan salah');
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    const onSubmit = () => {
        if (type === 'binding') {
            onBind(stateGlobal.upp_data.payment_info.payment_provider, otpOcde);
        } else if (type === 'unbinding') {
            onUnbind(stateGlobal.upp_data.payment_info.payment_provider, otpOcde);
        }
        onClick();
    };
    const redirectToPurchaseBalance = () => {
        if (stateGlobal.upp_data.recharge_url && stateGlobal.upp_data.recharge_url !== '') {
            window.location.assign(stateGlobal.upp_data.recharge_url);
        } else {
            alert('url not available');
        }
    };

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed bottom-0 md:inset-0 lg:inset-0 z-50 outline-none focus:outline-none">
                <div className="opacity-75 fixed inset-0 z-51 bg-blue-dark_blue" onClick={onClick}></div>
                <div className="relative mx-auto max-w-3xl md:px-32 lg:px-32 z-52">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative px-4 flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*body*/}
                        <div className="relative flex-auto w-full pt-10 pb-6">
                            <p className="font-face-tselbold mx-auto text-center text-topbar">
                                Masukkan Kode Verifikasi
                            </p>
                            <p className="text-base font-face-poppinslight text-center">
                                Kode verifikasi 6 digit telah dikirim ke nomor handphone yang terdaftar di{' '}
                                {BRI_DD === stateGlobal.upp_data.payment_info.payment_provider ? 'BRI' : 'BNI'}
                            </p>
                            <br></br>
                            <p className="text-base font-face-poppinslight-2 text-center">Kode verifikasi</p>
                            <br></br>
                            <div className="otp-group-6 mx-auto">
                                {otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        id={`otp-input-${index}`}
                                        type="text"
                                        inputMode="numeric"
                                        autoComplete="one-time-code"
                                        pattern="\d{1}"
                                        maxLength={2}
                                        className="otp-input-6"
                                        value={digit}
                                        onChange={(e) => changeHandler(index, e.target.value)}
                                    />
                                ))}
                            </div>
                            <br></br>

                            <p className="text-base font-face-poppinslight-2 text-red-error text-center">
                                {otpWarning !== '' ? otpWarning : ''}
                            </p>

                            <p className=" text-payment_code_title font-face-poppinsregular text-center">
                                {t('OTP_verification_word_3')}
                                {minutes === 0 && seconds === 0 ? (
                                    <span className=" font-bold" onClick={handleResendOTP}>
                                        Kirim Ulang
                                    </span>
                                ) : (
                                    <span className="font-bold ">
                                        {t('OTP_verification_word_4')}
                                        {minutes < 10 ? `0${minutes}` : minutes} :{' '}
                                        {seconds < 10 ? `0${seconds}` : seconds}
                                    </span>
                                )}
                            </p>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center p-6 flex-col">
                            <button
                                className="bg-red-tsel w-full text-white active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                disabled={isAllEmpty ? true : false}
                                onClick={onSubmit}
                            >
                                {text_button}
                            </button>
                            <BuyPackageButton
                                t={t}
                                isBalance={isBalance}
                                redirectToPurchaseBalance={redirectToPurchaseBalance}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OtpPage;
