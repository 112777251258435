import React from 'react';
import { PhoneNumberForm, PartnerHeader, TopBar, ModalAlert, LinkAjaWco } from 'presentations/shared';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import 'utilities/i18n';
import ErrorPage from '../error-page';
import useViewModel from './ConnectingPageViewModel';
import { OVO } from 'utilities';
import Failed from '../../assets/images/failed.png';
import LoadingComponent from 'presentations/shared/LoadingComponent';
import { ConnectingPageCard } from './Components';

// static connecting page contain Terms and Condition and phone number form for OVO
export default function ConnectingPage() {
    const { t } = useTranslation();
    const stateGlobal = useSelector((state) => state.upp_data);
    const {
        body,
        isLinkAjaWco,
        error,
        linkajaAuth,
        linkajaUrl,
        goToPreviousPath,
        partner,
        number,
        setNumber,
        submitOrdertoElisa,
        showModalAlert,
        setShowModalAlert,
        instruction,
        sanitizer,
        getStaticConnectingPageView,
        loadingTnC,
        minutes,
        seconds,
        isPaid
    } = useViewModel();

    // perform post to linkaja webcheckout
    if (isLinkAjaWco) {
        return <LinkAjaWco auth={linkajaAuth} action={linkajaUrl} />;
    }

    // show error page or redirect to failed url
    if (error) {
        if (stateGlobal.failed_url && stateGlobal.failed_url !== '') {
            window.location.replace(stateGlobal.failed_url);
        } else {
            return <ErrorPage />;
        }
    }

    return (
        <>
            <div className="flex flex-col flex-grow bg-gray-200 lg:px-72 xl:px-98 h-screen">
                <TopBar partner={partner} text={t('confirm')} onClick={goToPreviousPath} />
                {false && <div className="h-full flex flex-col w-full justify-between">
                    <div className="bg-gray-200">
                        <div className="px-8 lg:px-20 py-4 lg:pb-6 bg-white">
                            <PartnerHeader channel={instruction?.connector_data?.url_icon_channel} partnerlogo={instruction?.connector_data?.url_icon_partner} />
                            {loadingTnC ? <LoadingComponent /> : null}
                            {instruction &&
                            instruction.connector_data &&
                            !instruction.default_connecting_page_status ? (
                                <div className="mt-10 bg-white">
                                    <p className="text-base font-face-poppinsbold lg:text-lg">
                                        {instruction.connector_data.title}
                                    </p>
                                    <div className="mt-5">
                                        <div
                                            className="instruction font-face-poppinsregular text-sm lg:text-base"
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizer(instruction.connector_data.description),
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="h-36 w-36 lg:h-60 lg:w-60 mx-auto mt-4 lg:mt-4">
                                        <img src={Failed} alt="Failed" />
                                    </div>
                                    <p className="mt-4 text-sm lg:text-xl font-face-poppinsbold text-center">
                                        {t('failed_tnc')}
                                    </p>
                                    <div className="flex">
                                        <button
                                            className="pt-3 pb-3 mx-auto "
                                            onClick={() => getStaticConnectingPageView()}
                                        >
                                            <p
                                                className={`text-xs font-face-poppinsbold text-blue-tselproduct underline`}
                                            >
                                                {t('reload')}
                                            </p>
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                        {partner === OVO || body.payment_info.bank_name === OVO ? (
                            <div className="px-8 lg:px-20 py-4 bg-white mt-2 lg:mt-4">
                                <PhoneNumberForm
                                    value={number}
                                    onInput={(e) => setNumber(e.target.value)}
                                    input={t('ovoinput')}
                                    placeholder={t('phoneplaceholder')}
                                    account={t('ovoaccount')}
                                    onSubmit={submitOrdertoElisa}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div className="bg-white h-22 w-full py-5 px-4 lg:px-20">
                        <button
                            type="submit"
                            className="rounded-3xl w-full mx-auto flex bg-red-tsel py-4 lg:py-5 px-5 hover:bg-red-500"
                            disabled={
                                !instruction ||
                                !instruction.connector_data ||
                                instruction.default_connecting_page_status
                            }
                            onClick={submitOrdertoElisa}
                        >
                            <p className="text-white text-button font-face-poppinsbold mx-auto">
                                {t('continuebutton')}
                            </p>
                        </button>
                    </div>
                </div>}
                <ConnectingPageCard
                body={body}
                partner={partner}
                number={number}
                setNumber={setNumber}
                submitOrdertoElisa={submitOrdertoElisa}
                instruction={instruction}
                sanitizer={sanitizer}
                getStaticConnectingPageView={getStaticConnectingPageView}
                loadingTnC={loadingTnC}
                minutes = {minutes}
                seconds = {seconds}
                isPaid = {isPaid}
                />
            </div>
            {showModalAlert && (
                <ModalAlert text={partner==OVO?t('ovoalert'):t('jeniusalert')} text_button="OK" onClick={() => setShowModalAlert(false)} />
            )}
        </>
    );
}
