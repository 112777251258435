import React from 'react';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import clsx from 'clsx';


export default function ButtonComponent({
    onClick,
    disabled,
    text,
    type,
    version = 1
}) {
    const { t } = useTranslation();
    return (
        <button
            type={type}
            className={clsx('rounded-3xl w-full mx-auto flex py-4 lg:py-5 px-5 ', version == 1 ? 'bg-red-tsel hover:bg-red-500' : 'border-2 border-red-tsel bg-white hover:bg-red-500')}
            disabled={disabled}
            onClick={onClick}
        >
            <p 
            className={clsx('text-button font-face-poppinsbold mx-auto ', version == 1 ? 'text-white' : 'text-red-tsel')}
            >
                {text}
            </p>
        </button>
    );
}
