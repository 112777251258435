import { CC, VA } from 'utilities';
import React from 'react';

export const PaymentPartnerLogo = (props) => {
    const { parameter, logo, title, items } = props;

    switch (parameter) {
        case title === 'balance':
            return <img src={logo} alt="Logo" className="max-w-9" />;

        case items.url_icon === '':
        case !items.url_icon:
            return <div className="bg-white max-h-5" data-testid="blank-div"/>;

        case items.category === CC:
            return <img src={items.url_icon} alt="Logo" className="max-h-9" />;

        case items.category === VA:
            return <img src={items.url_icon} alt="Logo" className="max-h-9" />;

        default:
            return <img src={items.url_icon} alt="Logo" className="max-h-9" />;
    }
};
