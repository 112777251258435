import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BuyPackageButton from './BuyPackageButton';
import PartnerHeader from './PartnerHeader';
import CloseIcon from '@mui/icons-material/Close';

function ModalSelectBind({ onClick_1, onClick_2, title_text, text, text_button_1, text_button_2, isBalance, img, onClose }) {
    const { t } = useTranslation();
    const stateGlobal = useSelector((state) => state);

    const redirectToPurchaseBalance = () => {
        if (stateGlobal.upp_data.recharge_url && stateGlobal.upp_data.recharge_url !== '') {
            window.location.assign(stateGlobal.upp_data.recharge_url);
        } else {
            alert('url not available');
        }
    };

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed bottom-0 md:inset-0 lg:inset-0 z-50 outline-none focus:outline-none">
                <div className="opacity-75 fixed inset-0 z-51 bg-blue-dark_blue" onClick={() => onClose(false)} ></div>
                <div className="relative w-full mx-auto max-w-3xl md:px-32 lg:px-32 z-52">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative px-4 flex flex-col w-full bg-white outline-none focus:outline-none">
                        <button
                            className="flex absolute top-4 justify-center md:hidden lg:hidden text-gray-pull hover:text-gray-700 w-11/12 h-3"
                            onClick={() => onClose(false)}
                            data-testid="close-button"
                        >
                           <span className='w-1/4 h-3 rounded-md bg-gray-300'/>
                        </button>
                        <button
                            className="md:flex lg:flex hidden absolute right-6 top-3 text-gray-pull hover:text-gray-700 w-3 h-3"
                            onClick={() => onClose(false)}
                            data-testid="close-button"
                        >
                            <CloseIcon color="inherit" />
                        </button>
                        {/*body*/}
                        <div className=" flex-auto w-full pt-10 pb-6">
                            <PartnerHeader channel={stateGlobal.upp_data.channel} partnerlogo={img} />
                            <p className="font-face-tselbold mx-auto text-center text-topbar">{title_text}</p>
                            <p className="text-base font-face-poppinslight text-center">{text}</p>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center p-6 flex-col">
                            <button
                                className="bg-red-tsel w-full text-white active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={onClick_1}
                            >
                                {text_button_1}
                            </button>
                            <button
                                className=" w-full text-red-700 active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={onClick_2}
                            >
                                {text_button_2}
                            </button>
                            <BuyPackageButton
                                t={t}
                                isBalance={isBalance}
                                redirectToPurchaseBalance={redirectToPurchaseBalance}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalSelectBind;
