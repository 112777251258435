import React from 'react';
import { GOPAY, XENDIT_WALLET } from 'utilities';

export default function PhoneNumberForm({
    onInput,
    value,
    input,
    placeholder,
    account,
    partner,
    customer_info,
    error,
    onSubmit,
    disabled = false
}) {
    const handleSubmit = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onSubmit();
        }
    };

    return (
        <div className="mt-2">
            <p className="font-face-poppinsbold text-lg">{account}</p>
            <p className="font-face-poppinsregular text-sm text-gray-600 mt-2">{input}</p>
            <p className="font-face-poppinsregular text-sm text-gray-400 mt-5">{placeholder}</p>
            <div className="flex flex-row w-full h-auto mb-5 lg:w-1/2">
                <div className="h-auto w-full">
                    <form className="" onKeyDown={handleSubmit}>
                        <label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <span class="text-gray-500 sm:text-sm">+62</span>
                                </div>
                                <input
                                    type="text"
                                    name="price"
                                    id="price"
                                    class="block w-full rounded-md border-0 py-1.5 pl-12 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm/6"
                                    placeholder={""}
                                    disabled={disabled}
                                    value={value}
                                    onInput={onInput}
                                    onKeyDown={(e) => {
                                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    );
}
