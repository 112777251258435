import React from 'react';
import { PhoneNumberForm, PartnerHeader, CashtagForm } from 'presentations/shared';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import { JENIUS, OVO } from 'utilities';
import LoadingComponent from 'presentations/shared/LoadingComponent';
import PartnerInstructionSegment from './PartnerInstructionSegment';
import { ButtonComponent } from 'presentations/shared/components';

export default function ConnectingPageCard({
    body,
    partner,
    number,
    setNumber,
    submitOrdertoElisa,
    instruction,
    sanitizer,
    getStaticConnectingPageView,
    loadingTnC,
    minutes,
    seconds,
    isPaid,
}) {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col w-auto justify-between rounded-2xl border-2 border-white m-4 bg-white bg-opacity-50 gap-2 px-8 py-4 lg:px-20 lg:pb-6	">
            {loadingTnC ? <LoadingComponent /> : null}
            <PartnerHeader
                channel={instruction?.connector_data?.url_icon_channel}
                partnerlogo={instruction?.connector_data?.url_icon_partner}
                isTransparant={true}
            />
            {partner === OVO || body?.payment_info?.bank_name === OVO ? (
                <PhoneNumberForm
                    value={number}
                    onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        setNumber(e.target.value)
                    }}
                    input={t('ovoinput')}
                    placeholder={t('phoneplaceholder')}
                    account={t('ovoaccount')}
                    onSubmit={submitOrdertoElisa}
                />
            ) : null}
            {partner === JENIUS || body?.payment_info?.bank_name === JENIUS ? (
                <CashtagForm
                onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                    setNumber(e.target.value)
                }}
                    value={number}
                    placeholder={t('cashtag_placeholder')}
                    account={partner}
                    onSubmit={submitOrdertoElisa}
                    disabled={false}
                />
            ) : null}
            <PartnerInstructionSegment
                instruction={instruction}
                sanitizer={sanitizer}
                getStaticConnectingPageView={getStaticConnectingPageView}
            />

            <div className="h-22 w-full py-5 px-4 lg:px-20">
                {isPaid ? (
                    <ButtonComponent
                        onClick={submitOrdertoElisa}
                        disabled={
                            !instruction || !instruction.connector_data || instruction.default_connecting_page_status
                        }
                        text={`${t('already_paid')}     ${minutes} : ${seconds} `}
                        type={'submit'}
                        version={2}
                    />
                ) : (
                    <ButtonComponent
                        onClick={submitOrdertoElisa}
                        disabled={
                            !instruction || !instruction.connector_data || instruction.default_connecting_page_status
                        }
                        text={t('pay_now_button')}
                        type={'submit'}
                        version={1}
                    />
                )}
            </div>
        </div>
    );
}
