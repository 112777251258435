import { handleSelect, DANA, GOPAY, QRIS, SHOPEEPAY, XENDIT_WALLET, checkIOS } from 'utilities';

export default function PaymentInfo(selectedValue, body) {
    console.log('manage paymentInfo');
    console.log(selectedValue);

    const payment = handleSelect(selectedValue);

    const payment_info = {
        payment_provider: payment.provider,
        bank_name: payment.name || '',
        ios_platform: checkIOS()
    };

    if (payment.provider === QRIS) {
        payment_info.bank_name = GOPAY;
    }

    const setShopeepayInvoice = () => {
        payment_info.payment_invoice = null;
    };

    const filterListPaymentPartner =
        body.list_payment_partner?.filter((item) => item.partner_provider === payment.provider) || [];

    if (filterListPaymentPartner?.length !== 0) {
        if (filterListPaymentPartner[0]?.bind_info?.status === 'binding') {
            if (filterListPaymentPartner[0].method.includes('direct_debit')) {
                payment_info.bank_name = filterListPaymentPartner[0].bank_name || 'binding';
                payment_info.payment_id = (payment.provider === GOPAY || payment.provider === DANA) ? body.customer_details.cust_phone : '';
                payment.provider === SHOPEEPAY && setShopeepayInvoice();
            }
        }
    }

    return payment_info;
}
