import React from 'react';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import Failed from '../../../assets/images/failed.png';

export default function PartnerInstructionSegment({
    instruction,
    sanitizer,
    getStaticConnectingPageView,
}) {
    const { t } = useTranslation();
    return (
        <>
            {instruction && instruction.connector_data && !instruction.default_connecting_page_status ? (
                <div className="mt-10">
                    <p className="text-base font-face-poppinsbold lg:text-lg">{instruction.connector_data.title}</p>
                    <div className="mt-5">
                        <div
                            className="instruction font-face-poppinsregular text-sm lg:text-base"
                            dangerouslySetInnerHTML={{
                                __html: sanitizer(instruction.connector_data.description),
                            }}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className="h-36 w-36 lg:h-60 lg:w-60 mx-auto mt-4 lg:mt-4">
                        <img src={Failed} alt="Failed" />
                    </div>
                    <p className="mt-4 text-sm lg:text-xl font-face-poppinsbold text-center">{t('failed_tnc')}</p>
                    <div className="flex">
                        <button className="pt-3 pb-3 mx-auto " onClick={() => getStaticConnectingPageView()}>
                            <p className={`text-xs font-face-poppinsbold text-blue-tselproduct underline`}>
                                {t('reload')}
                            </p>
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
