import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@material-ui/core';

const TooltipComponent = ({ text }) => {
  const [open, setOpen] = useState(false);

  const useStyle = makeStyles((theme) => ({
    tooltip: {
      backgroundColor: '#001A41',
      padding: '.85rem',
      fontSize: '.85rem',
    },
    arrow: {
      color: '#001A41',
    }
  }));

  return (
    <Tooltip title={text} onClose={() => setOpen(false)} open={open} arrow classes={useStyle()}>
      <InfoOutlinedIcon onClick={() => setOpen(!open)} color="inherit" style={{ fontSize: '1.3rem' }}/>
    </Tooltip>
  );
};

export default TooltipComponent;