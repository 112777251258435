import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Header, MerchantBarcodeInfo, PaymentCodeInstructionSection, ProductDetail,PaymentCountDown, BoxInfo  } from 'presentations/shared';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import useViewModel from './PaymentCodePageViewModel';
import { getToken } from 'utilities';

// payment code page for merchant and virtual_account
function PaymentCodePage() {
    const { t } = useTranslation();
    const {
        total,
        detail,
        channel,
        hours,
        minutes,
        seconds,
        code,
        payment_info,
        notify,
        redirect,
        app,
        setToken,
        token,
        instructions,
        getInstructions,
        handleDownloadImage,
        download
    } = useViewModel();

    useEffect(() => {
        setToken(getToken);
        if (token) {
            getInstructions(token, payment_info.payment_provider, payment_info.bank_name ? payment_info.bank_name : null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);


    return (
        <div className="flex flex-grow h-full lg:min-h-screen md:h-full bg-gray-200 w-full lg:px-96 xl:px-98">
            <div className="h-full w-full pt-12 lg:w-full md:mb-0 lg:mb-0 mb-40">
                <MerchantBarcodeInfo
                    total={total}
                    channel={channel}
                    hours={hours}
                    minutes={minutes}
                    seconds={seconds}
                    notify={notify}
                    code={code}
                    fee={detail.item_fee ? detail.item_fee : 0}
                    download={download}
                />
                 <div className="p-4 bg-white mt-2 h-auto w-full border-b-2">
                    <div className={`mt-2`}>
                        <PaymentCountDown hours={hours} minutes={minutes} seconds={seconds} text={t('complete_the_payment')} />
                    </div>
                    <BoxInfo text={t('boxinfo_payment_code')} />
                </div>
                <div className="w-full">
                    <div className="-mt-2 lg:mt-4 md:mt-0">
                        <ProductDetail items={detail} />
                    </div>
                    <p className="text-category text-gray-hero_title px-4 pt-6 pb-3 font-face-poppinsbold">
                        {t('payment_instruction')}
                    </p>
                    <PaymentCodeInstructionSection
                        payment_info={payment_info}
                        partner={channel}
                        instructions={instructions}
                    />
                    <div className="bg-white md:static lg:static fixed bottom-0 h-auto w-full flex flex-wrap content-center py-5 px-4 mt-2">
                        <button
                            className="border-red-tsel border rounded-3xl w-full h-auto bg-red-tsel p-4 hover:bg-red-500 mb-3"
                            onClick={() => {
                                handleDownloadImage();
                            }}
                        >
                            <p className="text-white text-button font-face-poppinsbold">{t('download_payment_code')}</p>
                        </button>
                        <button
                            className="border-red-tsel border rounded-3xl w-full h-auto bg-white p-4 hover:bg-red-500"
                            onClick={() => {
                                window.location.replace(redirect);
                            }}
                        >
                            <p className="text-red-tsel text-button font-face-poppinsbold">{t('back_to_shopping')}</p>
                        </button>
                    </div>
                </div>
            </div>
            <Header channel={app} />
            <ToastContainer />
        </div>
    );
}

export default PaymentCodePage;
