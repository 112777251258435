import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { submitOrder } from 'core/controller';
import { requestOTPUseCase, saveOtpUseCase } from './../../../domain/use-case/auth';
import { useDispatch, useSelector } from 'react-redux';
import 'utilities/i18n';
import i18next from 'i18next';
import {
    setPaymentInfo,
    setTotalPrice,
    setItemFee,
    setPlatformFee,
    setPartnerPrice,
    setStaticState,
    setLoadingState,
    setErrorState,
    setPromo,
} from 'adapters';
import {
    submitPaymentToElisa,
    bindAccount,
    unbindAccount,
    checkAccountBindingStatus,
    getValidateToken,
    authBindAccount,
    inquiryBCA,
    requestOtpEsbUseCase,
    validateOtpEsbUseCase,
} from 'domain/use-case';
import {
    BNI_DD,
    BRI_DD,
    LINKAJA_WCO,
    MANDIRI_DD,
    XENDIT_WALLET,
    price,
    handleSelect,
    BCA_DD,
    stringToPrice,
} from 'utilities';
import { TotalPrice } from 'presentations/shared';
import DirectSubmit from './Services/DirectSubmit';
import LinkAjaWco from './Services/LinkAjaWco';
import MandiriDDWCO from './Services/MandiriDDWCO';
import PaymentInfo from './Services/PaymentInfo';
import { getPartnerPriceHandle, selectedPayment } from 'utilities/getPartnerPriceHandle';

export default function LandingPageViewModel() {
    const dispatch = useDispatch();
    const stateGlobal = useSelector((state) => state);
    const [isExpand, setIsExpand] = useState(true);
    let navigate = useNavigate();
    const [selectedValue, setSelectedValue] = useState('');
    const [data, setData] = useState([]);
    const [isLinkAjaWco, setIsLinkAjaWco] = useState(false);
    const [error, setError] = useState(false);
    const [maintenance, setMaintenance] = useState(false);
    const [bodytoElisa, setBodytoElisa] = useState({});
    const [balance, setBalance] = useState('');
    const [linkajaAuth, setLinkajaAuth] = useState('');
    const [linkajaUrl, setLinkajaUrl] = useState('');
    const [externalPayment, setExternalPayment] = useState();
    const [showModalAlert, setShowModalAlert] = useState(false);
    const [showUnBindSuccess, setShowUnBindSuccess] = useState(false);
    const [showModalDouPay, setShowModalDouPay] = useState(false);
    const [showBalanceAlert, setShowBalanceAlert] = useState(false);
    const [showModalOffer, setShowModalOffer] = useState(false);
    const [showModalOTP, setShowModalOTP] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [FailedOtp, setFailedOtp] = useState(false);
    const [otp, setOtp] = useState('');
    const [showModalOTPUnbinding, setShowModalOTPUnbinding] = useState(false);
    const [showModalUnbind, setShowModalUnbind] = useState(false);
    const [showModalAlertError, setShowModalAlertError] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [showModalInquiry, setShowModalInquiry] = useState(false);
    const [dataInquiry, setDataInquiry] = useState('');
    const [selectInquiry, setSelectInquiry] = useState('');
    const [isMandiriDDWco, setIsMandiriDDWco] = useState(false);
    const [mandiriDDRes, setMandiriDDRes] = useState({});
    const [deviceType, setDeviceType] = useState('');
    const [OTPCode, setOTPCode] = useState('');
    const [paymentId, setPaymentID] = useState('');
    const [promoSelected, setPromoSelected] = useState('');
    const [showPromo, setShowPromo] = useState(false);
    const [showModalPayment, setModalPayment] = useState(false);
    const [otpWarning, setOtpWarning] = useState(false);
    // const [total, setTotal] = useState(0);
    // const [productPrice, setProductPrice] = useState(0);
    // const [itemFee, setItemFee] = useState(0);
    // const [platformFee, setPlatformFee] = useState(0);

    let totalPay = 0;

    const [modalWording, setModalWording] = useState({
        modalText: '',
        modalTitle: '',
        modalButton1: '',
        modalButton2: '',
        modalImg: '',
        modalOnClick1: '',
        modalOnClick2: '',
    });

    const goToPreviousPath = () => {
        navigate(-1);
    };

    // call validate jwt api
    const validateOrder = async (auth) => {
        let body = { platform: deviceType.toLowerCase() };
        try {
            const response = await getValidateToken(auth, body);
            if (response) {
                setExternalPayment(response.additional_external_payment);
                // set initial price
                dispatch(setTotalPrice({ total_price: response.item_details.item_price }));
                dispatch(setPartnerPrice({ partner_price: response.item_details.item_price }));
                // setProductPrice(response.item_details.item_price);
                // store static value on global state
                dispatch(
                    setStaticState({
                        failed_url: response.redirect_url_failed,
                        success_url: response.redirect_url,
                        language: response.lang ? response.lang : 'id',
                        item_detail: response.item_details,
                        channel: response.channel,
                        upp_type: response.upp_type,
                        transaction_id: response.transaction_id,
                        client_key: response.client_key,
                        back_url: response.redirect_url_back,
                        home_url: response.redirect_url_logo,
                        recharge_url: response.redirect_url_topup,
                    })
                );
                setBalance(response.balance);
                setBodytoElisa(response);
                i18next.changeLanguage(response.lang ? response.lang : 'id');
                if (
                    response.list_payment_partner &&
                    response.list_payment_partner.length === 0 &&
                    !response.balance &&
                    !response.list_bank &&
                    // response.list_bank.length === 0 &&
                    response.additional_external_payment.length === 0
                ) {
                    // show maintenance page if no balance, list_bank, list_payment_partner, additional_external_partner on response
                    setMaintenance(true);
                    dispatch(setLoadingState({ loading: false }));
                } else if (response.upp_type === 'direct') {
                    // perform direct hit updatePayment to Elisa without rendering payment partner list
                    DirectSubmit(response, auth, totalPay, dispatch, navigate, setError, stateGlobal, {
                        setLinkajaAuth,
                        setLinkajaUrl,
                        setIsLinkAjaWco,
                    });
                } else if (response.list_payment_partner) {
                    // set new partner with partner_provider value === linkaja, so only one linkaja partner is shown on list
                    // let cekLinkAja = JSON.parse(JSON.stringify(response.list_payment_partner)).filter(
                    //     (word) => word.display_name === 'LinkAja'
                    // );

                    // const listPartner = setListPaymentPartner(response, cekLinkAja, deviceType);
                    // setRecomendationData(response.recommendation_payment);
                    setData(response.list_payment_partner);
                }
                if (response.upp_type !== 'direct') {
                    dispatch(setLoadingState({ loading: false }));
                }
            } else {
                setError(true);
                dispatch(setLoadingState({ loading: false }));
            }
        } catch (e) {
            setError(true);
            dispatch(setLoadingState({ loading: false }));
            dispatch(setErrorState({ is_error: true }));
        }
    };

    const isNotEnoughBalance = () => {
        const paymentProvider = stateGlobal.upp_data.payment_info.payment_provider;

        return (
            paymentProvider === 'balance' &&
            (bodytoElisa.msisdn_type !== 'postpaid' || bodytoElisa.is_gift_transaction) &&
            parseInt(bodytoElisa.balance) < stateGlobal.upp_data.total_price
        );
    };

    const isNotEnoughDirectDebitBalance = () => {
        let listPaymentPartner = bodytoElisa.list_payment_partner;
        const paymentProvider = stateGlobal.upp_data.payment_info.payment_provider;

        return (
            listPaymentPartner.filter((word) => word.partner_provider === paymentProvider)[0].bind_info &&
            listPaymentPartner.filter((word) => word.partner_provider === paymentProvider)[0].bind_info.status ===
                'binding' &&
            listPaymentPartner.filter((word) => word.partner_provider === paymentProvider)[0].bind_info.token_info
                .balance < stateGlobal.upp_data.total_price
        );
    };

    const isUnbinding = () => {
        const paymentProvider = stateGlobal.upp_data.payment_info.payment_provider;

        return (
            bodytoElisa.list_payment_partner.filter((word) => word.partner_provider === paymentProvider)[0].bind_info &&
            bodytoElisa.list_payment_partner.filter((word) => word.partner_provider === paymentProvider)[0].bind_info
                .status === 'unbinding'
        );
    };

    const isDirectDebit = () => {
        const paymentProvider = stateGlobal.upp_data.payment_info.payment_provider;
        return (
            bodytoElisa.list_payment_partner
                .filter((word) => word.partner_provider === paymentProvider)[0]
                ?.method?.includes('direct_debit') || false
        );
    };

    const isOfferToCustomer = () => {
        const paymentProvider = stateGlobal.upp_data.payment_info.payment_provider;
        return (
            bodytoElisa.list_payment_partner.filter((word) => word.partner_provider === paymentProvider)[0]
                .is_offer_to_customer === true && isDirectDebit()
        );
    };

    // direct debit payment selected
    const handlingDirectDebit = async () => {
        if (stateGlobal.upp_data.payment_info.payment_provider === MANDIRI_DD) {
            // bind mandiri direct debit
            onBind();
        } else if (isOfferToCustomer()) {
            setShowModalOffer(true);
        } else {
            submitOrder(
                { ...bodytoElisa, payment_info: stateGlobal.upp_data.payment_info },
                externalPayment,
                stateGlobal.upp_data.token,
                stateGlobal.upp_data.total_price,
                navigate,
                '',
                '',
                stateGlobal.upp_data.partner_price
            );
        }
    };

    const handlingOTP = useCallback(() => {
        if (OTPCode) {
            submitOrdertoElisa();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OTPCode]);

    useEffect(() => {
        handlingOTP();
    }, [OTPCode, handlingOTP]);

    const handlingBindingPartner = async () => {
        const paymentPartner = stateGlobal.upp_data.payment_info.payment_provider;
        const payment = bodytoElisa.list_payment_partner.filter(
            (item) => item.partner_provider === paymentPartner && item.is_otp_payment
        );
        if (payment.length > 0 && OTPCode.length === 0) {
            const payload = {
                ...bodytoElisa,
                payment_info: { ...stateGlobal.upp_data.payment_info, payment_invoice: selectInquiry || '' },
            };

            if (paymentPartner === BCA_DD && OTPCode.length === 0 && selectInquiry === '') {
                const response = await inquiryBCA(paymentPartner, stateGlobal.upp_data.token);
                if (response.status_code === '00' && response.status_desc === 'SUCCESS') {
                    setDataInquiry(response.phones);
                    setShowModalInquiry(true);
                    dispatch(setLoadingState({ loading: false }));
                } else {
                    setShowModalAlertError(true);
                    setShowModalInquiry(false);
                }
            } else {
                const response = await requestOTPUseCase(payload, stateGlobal.upp_data.token);
                if (response.status_code === '00' && response.status_desc === 'Success') {
                    setShowModalOTP(true);
                    setShowModalInquiry(false);
                    setPaymentID(response.transaction_id);
                    dispatch(setLoadingState({ loading: false }));
                } else {
                    setShowModalAlertError(true);
                    setShowModalOTP(false);
                }
            }
        } else if (payment.length > 0 && OTPCode.length === 6) {
            let otpBody = { payment_otp: OTPCode };

            const response = await saveOtpUseCase(otpBody, stateGlobal.upp_data.token);
            if (response.status_code === '00' && response.status_desc === 'SUCCESS') {
                submitOrder(
                    { ...bodytoElisa, payment_info: stateGlobal.upp_data.payment_info },
                    externalPayment,
                    stateGlobal.upp_data.token,
                    stateGlobal.upp_data.total_price,
                    navigate,
                    OTPCode,
                    bodytoElisa.transaction_id,
                    stateGlobal.upp_data.partner_price
                );
            }
            // show balance is not enough pop up for direct debit
        } else if (isNotEnoughDirectDebitBalance()) {
            // show balance is not enough pop up for direct debit
            setShowBalanceAlert(true);
        } // call updatePayment to Elisa
        else {
            submitOrder(
                { ...bodytoElisa, payment_info: stateGlobal.upp_data.payment_info },
                externalPayment,
                stateGlobal.upp_data.token,
                stateGlobal.upp_data.total_price,
                navigate,
                OTPCode,
                bodytoElisa.customer_details.cust_phone,
                stateGlobal.upp_data.partner_price
            );
        }
    };

    const handlingNonDirectDebit = async () => {
        const paymentPartner = stateGlobal.upp_data.payment_info.payment_provider;
        const linkAjaWco = bodytoElisa.list_payment_partner.filter((item) => item.partner_provider === LINKAJA_WCO)[0]

        if (isNotEnoughBalance()) {
            // show balance is not enough pop up for balance
            setShowBalanceAlert(true);
        } else if ((paymentPartner === LINKAJA_WCO) && (bodytoElisa.upp_type === 'indirect_without_order') && !linkAjaWco.is_connecting_page) {
            dispatch(setLoadingState({ loading: true }));
            try {
                // call updatePayment to Elisa
                const response = await submitPaymentToElisa(
                    stateGlobal.upp_data.payment_info,
                    stateGlobal.upp_data.token
                );
                if (response) {
                    if (response.partner_type === 4) {
                        // perform post to linkaja webcheckout
                        LinkAjaWco(
                            response.content,
                            response.payment_url,
                            dispatch,
                            { setLinkajaAuth, setLinkajaUrl, setIsLinkAjaWco },
                            setError
                        );
                    }
                }
            } catch (e) {
                setError(true);
                dispatch(setLoadingState({ loading: false }));
            }
        } else {
            submitOrder(
                { ...bodytoElisa, payment_info: stateGlobal.upp_data.payment_info },
                externalPayment,
                stateGlobal.upp_data.token,
                stateGlobal.upp_data.total_price,
                navigate,
                '',
                '',
                stateGlobal.upp_data.partner_price
            );
        }
    };

    const reqOtpEsb = async () => {
        dispatch(setLoadingState({ loading: true }));
        try {
            const response = await requestOtpEsbUseCase(stateGlobal.upp_data.token);

            if (response && response.status_code === '00') {
                setShowOTP(true);
            } else {
                setShowModalAlertError(true);
            }
        } catch {
            dispatch(setLoadingState({ loading: false }));
        }
    };
    const submitReqOtp = async (code) => {
        const body = {
            payment_otp: code,
        };
        dispatch(setLoadingState({ loading: true }));
        try {
            const res = await validateOtpEsbUseCase(body, stateGlobal.upp_data.token);
            if (res && res.status_code === '00') {
                setShowOTP(false);
                submit0neMethod();
            } else {
                dispatch(setLoadingState({ loading: false }));
                setShowOTP(false);
                setShowModalAlertError(true);
            }
        } catch {
            dispatch(setLoadingState({ loading: false }));
        }
    };

    const handleDoubleMethode = (methode) => {
        if (methode === 'dd') {
            submitMoreOneMethod();
        } else {
            try {
                dispatch(setLoadingState({ loading: true }));
                handlingNonDirectDebit();
                dispatch(setLoadingState({ loading: false }));
            } catch (e) {
                setError(true);
                dispatch(setLoadingState({ loading: false }));
            }
        }
    };

    const submit0neMethod = async () => {
        dispatch(setLoadingState({ loading: true }));
        const paymentProvider = stateGlobal.upp_data.payment_info.payment_provider;

        if (
            bodytoElisa.list_payment_partner.filter((word) => word.partner_provider === paymentProvider).length !== 0 &&
            isDirectDebit()
        ) {
            if (isUnbinding()) {
                // direct debit partner selected
                handlingDirectDebit();
            } else {
                handlingBindingPartner();
            }
        } else {
            handlingNonDirectDebit();
        }
    };

    const submitMoreOneMethod = () => {
        dispatch(setLoadingState({ loading: true }));
        const paymentProvider = stateGlobal.upp_data.payment_info?.payment_provider;

        try {
            if (paymentProvider !== '') {
                if (
                    bodytoElisa.list_payment_partner.filter((word) => word.partner_provider === paymentProvider)
                        .length !== 0 &&
                    isDirectDebit()
                ) {
                    if (isUnbinding()) {
                        navigate('/binding', {
                            state: {
                                token: stateGlobal.upp_data.token,
                                channel: bodytoElisa.channel,
                                payment_info: stateGlobal.upp_data.payment_info,
                                customer_info: bodytoElisa.customer_details,
                                redirect: bodytoElisa.redirect_url,
                                failed: bodytoElisa.redirect_url_failed,
                                partner_credential: bodytoElisa.list_payment_partner.filter(
                                    (item) => item.partner_provider === paymentProvider
                                )[0],
                            },
                        });
                    } else {
                        handlingBindingPartner();
                    }
                }
            } else {
                // show alert if no payment partner is selected
                setShowModalAlert(true);
            }
            dispatch(setLoadingState({ loading: false }));
        } catch (e) {
            setError(true);
            dispatch(setLoadingState({ loading: false }));
        }
    };

    // perform action on pay button
    const submitOrdertoElisa = () => {
        const { payment_provider: paymentProvider, bank_name } = stateGlobal.upp_data.payment_info;
        totalPay = TotalPrice(
            stateGlobal.upp_data.partner_price,
            stateGlobal.upp_data.item_fee,
            stateGlobal.upp_data.platform_fee
        ).formated;

        try {
            if (stateGlobal.upp_data.payment_info?.payment_provider !== '') {
                if (
                    bodytoElisa.upp_type !== 'direct' &&
                    paymentProvider !== 'balance' &&
                    bodytoElisa.list_payment_partner.filter(
                        (word) =>
                            word?.method?.length >= 2 &&
                            word?.bind_info?.status === 'unbinding' &&
                            (word?.partner_provider === paymentProvider || word?.bank_name === bank_name)
                    ).length !== 0
                ) {
                    setShowModalDouPay(true);
                    setModalWording({
                        ...setModalWording,
                        modalText: 'binding_wording_2',
                        modalTitle: 'connectaccount',
                        modalButton1: 'Connect',
                        modalButton2: 'Skip',
                        modalImg: paymentProvider,
                    });
                } else {
                    if (bodytoElisa.is_gift_transaction && paymentProvider === 'balance') {
                        isNotEnoughBalance() ? setShowBalanceAlert(true) : reqOtpEsb();
                    } else {
                        submit0neMethod();
                    }
                }
            } else {
                // show alert if no payment partner is selected
                setShowModalAlert(true);
            }
            dispatch(setLoadingState({ loading: false }));
        } catch (e) {
            setError(true);
            dispatch(setLoadingState({ loading: false }));
        }
    };

    // handle effect on radio button select action
    const handleChange = async (event) => {
        if (stateGlobal.upp_data.promo !== '' && event.target.value.split[0] !== 'gopay') {
            dispatch(setPromo({ promo: '' }));
            dispatch(setPaymentInfo({ payment_info: { promotion_id: '' } }));
        }
        setSelectedValue(event.target.value);

        const payment_info = PaymentInfo(event.target.value, bodytoElisa);

        dispatch(setPaymentInfo({ payment_info: { ...payment_info } }));

        const totalPrice = getPartnerPriceHandle(bodytoElisa, payment_info);

        dispatch(setTotalPrice({ total_price: totalPrice.TotalPrice }));
        dispatch(setItemFee({ item_fee: totalPrice.item_fee }));
        dispatch(setPlatformFee({ platform_fee: totalPrice.platform_fee }));
        dispatch(setPartnerPrice({ partner_price: totalPrice.partner_price }));
    };

    // binding function
    const onBind = async (partner) => {
        let binding_body = {
            payment_provider: stateGlobal.upp_data.payment_info.payment_provider,
        };
        if (partner === MANDIRI_DD || partner === BCA_DD) {
            // need to specify based on argument due to no radio button for mandiri direct debit, so it will not execute handleChange function
            binding_body = {
                payment_provider: partner,
            };
        }
        if (partner === MANDIRI_DD) {
            try {
                // directly hit binding API for mandiri direct debit
                const response = await bindAccount(binding_body, stateGlobal.upp_data.token);
                if (response && response.status_code === '00') {
                    // open mandiri web check out
                    MandiriDDWCO(response, dispatch, { setMandiriDDRes, setIsMandiriDDWco, setShowModalAlertError });
                } else if (response && response.data.status_desc.includes('Limit Binding Attempt')) {
                    setShowModalAlertError(true);
                    setErrorModalMessage('failed_limit');
                }
            } catch (err) {
                setShowModalAlertError(true);
            }
        } else if (partner === BCA_DD) {
            try {
                const response = await authBindAccount(binding_body, stateGlobal.upp_data.token);
                if (response && response.status_code === '00') {
                    redirectBinding(response);
                }
            } catch (err) {
                setShowModalAlertError(true);
            }
        } else {
            // navigate to binding page for dana and gopay
            navigate('/binding', {
                state: {
                    token: stateGlobal.upp_data.token,
                    channel: bodytoElisa.channel,
                    payment_info: stateGlobal.upp_data.payment_info,
                    customer_info: bodytoElisa.customer_details,
                    redirect: bodytoElisa.redirect_url,
                    failed: bodytoElisa.redirect_url_failed,
                    partner_credential: bodytoElisa.list_payment_partner.filter(
                        (item) => item.partner_provider === partner
                    )[0],
                },
            });
        }
    };

    const redirectBinding = (res) => {
        if (res.payment_provider === BCA_DD) {
            if (res.payment_response.binding_url && res.payment_response.binding_url !== null) {
                window.location.replace(res.payment_response.binding_url);
            } else if (res.payment_response.binding_info && res.payment_response.binding_info !== null) {
                window.location.assign(res.payment_response.binding_info);
            } else {
                window.location.replace(stateGlobal.upp_data.failed_url);
            }
        }
    };

    const onSuccessUnbind = async () => {
        setShowUnBindSuccess(true);
    };

    // unbinding function
    const onUnbind = async () => {
        const binding_body = {
            payment_provider: stateGlobal.upp_data.payment_info.payment_provider,
        };
        if (binding_body.payment_provider === BNI_DD) {
            setShowModalUnbind(false);
            const response = await requestOTPUseCase(
                {
                    payment_info: { ...stateGlobal.upp_data.payment_info, action: 'unbind' },
                },
                stateGlobal.upp_data.token
            );
            if (response && response.status_code === '00') {
                setShowModalOTPUnbinding(true);
                setShowUnBindSuccess(true);
                setPaymentID(response.transaction_id);
            } else {
                setShowModalAlertError(true);
                setShowModalOTPUnbinding(true);
            }
        } else {
            try {
                if (binding_body.payment_provider === XENDIT_WALLET) {
                    binding_body.bank_name = stateGlobal.upp_data.payment_info.bank_name;
                }
                // call unbinding api
                const response = await unbindAccount(binding_body, stateGlobal.upp_data.token);
                if (response && response.status_code === '00') {
                    validateOrder(stateGlobal.upp_data.token);
                    setShowUnBindSuccess(true);
                    setShowModalUnbind(false);
                } else {
                    setShowModalAlertError(true);
                    setShowModalUnbind(false);
                }
            } catch (err) {
                setShowModalAlertError(true);
                setShowModalUnbind(false);
            }
        }
    };

    const handleOTPUnbinding = (alertValue, word) => {
        setOtpWarning(word);
        if (alertValue === false) {
            setShowModalOTPUnbinding(true);
        }
    };

    // check status binding function
    const checkStatusBinding = async () => {
        const binding_body = {
            payment_provider: stateGlobal.upp_data.payment_info?.payment_provider,
        };
        try {
            const response = await checkAccountBindingStatus(binding_body, stateGlobal.upp_data.token);
            if (response && response.status_code === '00') {
                if (response.bind_info[0].token_info.balance) {
                    // getting binding status and show balance
                    bodytoElisa.list_payment_partner.filter(
                        (partner) => partner.partner_provider === response.bind_info[0].payment_provider
                    ).bind_info.status = response.bind_info[0].status === 'active' ? 'binding' : 'unbinding';
                    bodytoElisa.list_payment_partner.filter(
                        (partner) => partner.partner_provider === response.bind_info[0].payment_provider
                    ).bind_info.token_info.balance = response.bind_info[0].token_info.balance;
                } else {
                    bodytoElisa.list_payment_partner.filter(
                        (partner) => partner.partner_provider === response.bind_info[0].payment_provider
                    ).bind_info.status = response.bind_info[0].status === 'active' ? 'binding' : 'unbinding';
                }
            } else {
                setShowModalAlertError(true);
            }
        } catch (err) {
            setShowModalAlertError(true);
        }
    };

    const selectPromo = (data) => {
        if (stateGlobal.upp_data.payment_info.payment_provider === 'gopay') {
            if (stateGlobal.upp_data.promo === '' && stateGlobal.upp_data.payment_info.promotion_id !== data) {
                dispatch(setPromo({ promo: data }));
                dispatch(
                    setPaymentInfo({ payment_info: { ...stateGlobal.upp_data.payment_info, promotion_id: data } })
                );
            } else {
                dispatch(setPromo({ promo: '' }));
                dispatch(setPaymentInfo({ payment_info: { ...stateGlobal.upp_data.payment_info, promotion_id: '' } }));
            }
        } else {
            setModalPayment(true);
            setPromoSelected(data);
        }
    };

    const getSelectedPromo = () => {
        const data = { promo: '', description: '' };
        if (stateGlobal.upp_data.promo !== '' && bodytoElisa.payment_promo?.length > 0) {
            const selected = bodytoElisa.payment_promo?.filter(
                (promo) => promo.promotion_id === stateGlobal.upp_data.promo
            );
            data.promo = selected[0];
            const totalPromo = data.promo.promotion_amount.split('.')[0];
            data.description =
                data.promo.additional_info.type === 'CASHBACK'
                    ? `Cashback Rp${price(totalPromo)} GoPoints`
                    : `Potongan ${price(totalPromo)}`;
        }
        return data;
    };

    const setSelectedPromo = () => {
        if (stateGlobal.upp_data.promo !== '') {
            dispatch(
                setPaymentInfo({
                    payment_info: { promotion_id: stateGlobal.upp_data.promo },
                })
            );
        }
    };

    const setSelectedPaymentPromo = (payment) => {
        handleChange({ target: { value: payment } });
        setModalPayment(false);
        dispatch(setPromo({ promo: promoSelected }));
        dispatch(
                setPaymentInfo({
                    payment_info: { promotion_id: stateGlobal.upp_data.promo },
                })
            );
    };

    return {
        showModalOffer,
        showModalAlert,
        validateOrder,
        goToPreviousPath,
        submitOrdertoElisa,
        data,
        externalPayment,
        selectedValue,
        selectedValueParse: stateGlobal.upp_data.payment_info.payment_provider,
        handleChange,
        bodytoElisa,
        balance,
        selectPromo,
        getSelectedPromo,
        setSelectedPromo,
        showPromo,
        setShowPromo,
        showModalPayment,
        setModalPayment,
        setSelectedPaymentPromo,
        setSelectedValue,
        onBind,
        setShowModalUnbind,
        checkStatusBinding,
        showModalAlertError,
        showBalanceAlert,
        showModalUnbind,
        showModalOTPUnbinding,
        errorModalMessage,
        setShowModalOTPUnbinding,
        setShowBalanceAlert,
        setShowModalAlertError,
        setErrorModalMessage,
        setShowModalAlert,
        setShowModalOffer,
        onUnbind,
        maintenance,
        error,
        isLinkAjaWco,
        linkajaAuth,
        linkajaUrl,
        isMandiriDDWco,
        mandiriDDRes,
        deviceType,
        setDeviceType,
        setShowModalOTP,
        showModalOTP,
        OTPCode,
        setOTPCode,
        setShowOTP,
        // minutes,
        // seconds,
        handlingOTP,
        isExpand,
        setIsExpand,
        handleOTPUnbinding,
        otpWarning,
        paymentId,
        showModalDouPay,
        modalWording,
        setShowModalDouPay,
        handleDoubleMethode,
        setShowUnBindSuccess,
        showUnBindSuccess,
        showModalInquiry,
        setShowModalInquiry,
        dataInquiry,
        selectInquiry,
        setSelectInquiry,
        onSuccessUnbind,
        showOTP,
        otp,
        setOtp,
        submitReqOtp,
        // itemFee,
        // platformFee,
        // productPrice,
        // total,
        isNotEnoughBalance,
        handlingNonDirectDebit,
        handlingDirectDebit
    };
}
